import React, { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/common/Layout/layout"
import SEO from "../components/common/SEO/seo"
import BannerPage from "../components/banner/BannerPage/bannerPage"
import SectionTextImage from "../components/section/TextImage/textImage"
import PrintServices from "../components/section/GraphicProductionServices/graphicProductionServices"
import CallToAction from "../components/section/CallToAction/callToAction"

const ProduccionGraficaPage = () => {
    const data = useStaticQuery(graphql`
    query {
      produccionGraficaImage1: file(relativePath: { eq: "images/sections/produccion-grafica/impresion-360-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      produccionGraficaImage2: file(relativePath: { eq: "images/sections/produccion-grafica/asesoramiento-personalizado-ibxagency.png" }) {
        childImageSharp {
          fluid(maxWidth: 854) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return (
        <Layout>
            <SEO
                title="Imprenta Gráfica"
                description="Imprenta en Barcelona que ofrece servicios globales de impresión offset, digital y merchandising."
            />

            <BannerPage
                title="Expertos impresión offset, digital y merchandising"
                text="Producción publicitaria en todo tipos de soportes y tecnologías"
                color="blue"
            />

            <SectionTextImage
                title1="Impresión "
                title2="360"
                text={<Fragment>
                    Damos color a todos tus proyectos publicitarios mediante los mecanismos de <strong>impresión Offset</strong>, <strong>impresión digital</strong> y <strong>merchandising</strong>. Cubrimos todo tipo de publicidades gracias a que elaboramos trabajos de diferentes formatos, medidas y soportes. <br /><br />
                    Realizamos impresiones rápidas y económicas pero también hacemos tirajes largos y especiales.
                </Fragment>}
                image={data.produccionGraficaImage1.childImageSharp.fluid}
                imageAlt="Impresión 360 - Agencia Impresión Gráfica"
                color="blue"
                containerTextRight={true}
            />

            <SectionTextImage
                title1="Asesoramiento "
                title2="personalizado"
                text={<Fragment>
                    En IBX ofrecemos un <strong>acompañamiento publicitario profesional</strong> con todos nuestros clientes. En función del trabajo o proyecto a realizar, aconsejamos y proponemos con qué papeles, materiales y acabados es mejor producir un material gráfico para que tenga la mejor calidad y repercusión publicitaria.
                </Fragment>}
                image={data.produccionGraficaImage2.childImageSharp.fluid}
                imageAlt="Asesoramiento personalizado - Agencia Impresión Gráfica"
                color="blue"
                containerTextRight={false}
            />

            <PrintServices />

            <CallToAction
                color="blue"
                title="¿Quieres que le imprimamos tus proyectos?"
                text={<Fragment>
                    ¡Somos tu <strong>imprenta en Barcelona</strong>! <br />
                    ¡Dinos qué quieres imprimir y nosotros te diremos cómo hacerlo!
                </Fragment>}
                button="¿Empezamos?"
                buttonUrl="/contacto"
            />

        </Layout>
    )

}
export default ProduccionGraficaPage
